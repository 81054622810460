<template>
  <div id="other_site">

  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      advertList: [],
    };
  },
  created() {
    this.IsPC();
    this.getAdvertList();
  },
  mounted() {},
  watch: {},
  methods: {
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      if (!flag) {
        this.$router.push("/m_othersite");
      }
    },
    getAdvertList() {
      this.$request.post({
        url: "/ReportApi/business/advert/getAdvertInfo",
        params: {
          pageNo: 1,
          pageSize: 1,
          type: 6,
          tenantId: localStorage.getItem("tenantInfoid"),
        },
        success: (res) => {



          this.advertList = res;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#other_site {
  .admin_main_block {
    background: #fff;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    min-height: 855px;
    border-radius: 5px;
    .user_temp_title {
      margin: auto;
      padding-left: 20px;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 20px;
      height: 25px;
      margin-bottom: 20px;
      font-weight: bold;
    }
  }
}
</style>
